import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Download App</h2>
          <h4 className={classes.description}>
            Download the app on Apple App Store.
          </h4>
          <h4 className={classes.description}>
            <p>
              <a href="https://apps.apple.com/us/app/votesha/id1530302603">
                <img
                  src="app-store.svg"
                  height="46"
                  alt="Download on the App Store"
                />
              </a>
            </p>
          </h4>
          <h4 className={classes.description}>
            The Android version should be released by end of 2021.
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
